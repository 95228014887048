<template>
  <div class="about">
    <AboutC/>
  </div>
</template>

<script>
import AboutC from '../components/About/AboutC.vue'
export default {
  name: "About",
  components: {
    AboutC
  }
}
</script>

<style>


</style>